import React, { Component } from 'react';
import axios from "axios"
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button'
import moment from 'moment'


class NewsTable extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleSend = this.handleSend.bind(this)
    }

    handleClick(id) {
        return () => {
            this.props.onClick(id)
        }
    }

    handleSend(id) {
        return () => {
            this.props.onSend(id)
        }
    }

    render() {

        // Sort the news
        let sentNews = []
        let unsentNews = []
        for (let n of this.props.news) {
            if (n.sent) sentNews.push(n)
            else unsentNews.push(n)
        }


        return (<table className="table">
            <thead>
                <tr>
                    <th scope="col">News</th>
                    <th scope="col">Sent</th>
                    <th scope="col">Date</th>
                </tr>
            </thead>
            <tbody>
                {unsentNews.slice(0).reverse().map((object, i) => { return <tr key={object.id}><td className="hover"  onClick={this.handleClick(object.id)}>{object.news.split(/\r?\n/)[0]}</td><td>{object.sent === false ? "No" : "Yes"}</td><td>{object.sent === false ? <Button onClick={this.handleSend(object.id)}>Send</Button> : moment(object.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td></tr>} )}
                {sentNews.slice(0).reverse().map((object, i) => { return <tr key={object.id}><td className="hover"  onClick={this.handleClick(object.id)}>{object.news.split(/\r?\n/)[0]}</td><td>{object.sent === false ? "No" : "Yes"}</td><td>{object.sent === false ? <Button onClick={this.handleSend(object.id)}>Send</Button> : moment(object.date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td></tr>} )}

            </tbody>
        </table>);
    }

}

export default NewsTable