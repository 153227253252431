import React, { Component } from 'react';
import './Login.css';
import axios from 'axios'

class Login extends Component {



    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setUserName = this.setUserName.bind(this)

        this.setPassword = this.setPassword.bind(this)


        let baseUrl = window.location.protocol + "//" + window.location.host + "/"
        this.state = {
            username: "",
            password: "",
            isAuthenticated: false,
            url: baseUrl === "http://localhost:3000/" ? "http://localhost:8000/" : "https://vyscore.com/"
        }
    }

   

    handleSubmit(e) {
        function getCookie(name) {
            var cookieValue = null;
            if (document.cookie && document.cookie !== '') {
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i].trim();
                    if (cookie.substring(0, name.length + 1) === (name + '=')) {
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                        break;
                    }
                }
            }
            return cookieValue;
        }
        
        var csrftoken = getCookie('csrftoken');
        e.preventDefault();
        let config = {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken
            },
            credentials: "same-origin",
            withCredentials: true
        }
    
        axios.post(this.state.url + 'api/explorers/login/', {
            username: this.state.username,
            password: this.state.password,
        }, config)
            .then(res =>
                // set state
                this.props.onLogin()

            ).catch(error => { });
    }

    setUserName(username){
        this.setState({ username: username })
    }

    setPassword(password) {
        this.setState({ password: password })
    }

    render() {
        return (
            <div className="login-wrapper container">
                <h1>Please Log In</h1>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <label>Username</label>
                        <input className="form-control" type="text" onChange={e => this.setUserName(e.target.value)} />
                    </div>
                    <div className="row">
                        <label>Password</label>
                        <input className="form-control" type="password" onChange={e => this.setPassword(e.target.value)} />
                    </div>
                    <div className="row">
                        <button className="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        )
    }
}


export default Login
    


    

    
