import { Component } from 'react'
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TextFieldInput.css'

class TextFieldInput extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }


    
    
    handleChange(e) {
        //e.target.value = e.target.value.replace(/[^a-zA-Z.,']/g, '');

        let data = {
            "target": {
                "name": this.props.name,
                "value": e.target.value
            }
        }

        this.props.onChange(data);
    }


    onPaste(e) {
        //e.preventDefault()
        //e.target.value = e.target.value.replace(/[^a-zA-Z.,']/g, '');
    }


    render() {

        return (

            <div className="input-box">
                <Form.Control className="name-input" size="lg" type="text" onChange={this.handleChange} onPaste={this.onPaste} required/>
            </div>

        )
    }

}

export default TextFieldInput