import { Component } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import './PhoneFieldInput.css'


class PhoneFieldInput extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        let data = {
            "target": {
                "name": this.props.name,
                "value": "+" + e
            }
        }
        this.props.onChange(data);
    }

    render() {
        return (
            <div className="input-box">
                <PhoneInput containerClass="phone-container" inputStyle={{"fontSize": "1.25rem", "height": "calc(1.5em + 1rem + 2px)", "width": "100%"}} buttonClass="phone-button" size="lg" country={'us'} onChange={this.handleChange}/>
            </div>

        )
    }

}

export default PhoneFieldInput