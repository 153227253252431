import React, { Component } from 'react'
import Login from '../Login/Login'
import NewsTable from './NewsTable'
import ExplorersTable from './ExplorersTable'
import NewsModal from './NewsModal'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment'

import './Dashboard.css'
import axios from "axios"

axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleTextAreaChange = this.handleTextAreaChange.bind(this)
        this.handleGenderFilterChange = this.handleGenderFilterChange.bind(this)
        this.handleOlderThanFilterChange = this.handleOlderThanFilterChange.bind(this)
        this.handleYoungerThanFilterChange = this.handleYoungerThanFilterChange.bind(this)

        this.handleDateFieldChange = this.handleDateFieldChange.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
        this.handleSubmitNews = this.handleSubmitNews.bind(this)
        this.retrieveNews = this.retrieveNews.bind(this)
        this.retrieveExplorers = this.retrieveExplorers.bind(this)
        this.handleNewsSelect = this.handleNewsSelect.bind(this)
        this.handleSend = this.handleSend.bind(this)
        this.updateNews = this.updateNews.bind(this)
        this.getCookie = this.getCookie.bind(this)

        let baseUrl = window.location.protocol + "//" + window.location.host + "/"

        this.state = {
            username: "",
            error: "",
            isAuthenticated: undefined,
            url: baseUrl === "http://localhost:3000/" ? "http://localhost:8000/" : "https://vyscore.com/",
            showModal: false,
            showUpdateModel: false,
            schedule: "",
            news: "",
            newsObjects: [],
            explorersObject: [],
            genderFilter: undefined,
            
            //requestConfig: {}
        }
    }

    componentDidMount() {
        this.getSession();
        this.retrieveNews();
        this.retrieveExplorers()
    }

    getCSRF() {
        axios.get(this.state.url + 'api/csrf/')
            .then((res) => {
            })
            .catch((err) => {
                console.log(err);
            });
    }


    getSession() {


        axios.get(this.state.url + 'api/session/').then(res => {
            if (res.data.isAuthenticated) {
                this.setState({ isAuthenticated: true });
            }
        }).catch(error => {
            this.setState({ isAuthenticated: false });
            this.getCSRF();
        })
    }

    handleLogin() {
        this.setState({ isAuthenticated: true })
    }

    handleOpenModal() {
        this.setState({ showModal: true })
    }

    handleCloseModal() {
        this.setState({ showModal: false, showUpdateModal: false})
    }

    handleTextAreaChange(e) {
        this.setState({ news: e.target.value })
    }

    handleGenderFilterChange(e) {
        this.setState({ genderFilter: e.target.value })
    }

    handleOlderThanFilterChange(e) {
        this.setState({ olderThanFilter: e.target.value })
    }

    handleYoungerThanFilterChange(e) {
        this.setState({ youngerThanFilter: e.target.value })
    }

    handleDateFieldChange(e) {
        let date = e.toDate().toISOString()
        this.setState({ schedule: date })
    }

    handleLogout() {

        axios.get(this.state.url + 'api/explorers/logout/').then(res => {
            this.setState({ isAuthenticated: false });
        }).catch(error => {
            this.setState({ isAuthenticated: false });
        })
    }

    handleSubmitNews() {
        var csrftoken = this.getCookie('csrftoken');

        

        let data = {news: this.state.news, 
            gender: this.state.genderFilter == "" ? null : this.state.genderFilter, 
            older_than: this.state.olderThanFilter == "" ? null : this.state.olderThanFilter, 
            younger_than: this.state.youngerThanFilte == "" ? null : this.state.youngerThanFilter }

        let config = {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken
            },
            credentials: "same-origin",
            withCredentials: true
        }
        axios.post(this.state.url + 'api/explorers/news/', data, config).then(res => {
            this.retrieveNews()
        }).catch(error => {
        })
        this.handleCloseModal()
    }

    retrieveNews() {
        axios.get(this.state.url + 'api/explorers/news/').then(res => {
            this.setState({newsObjects: res.data})
        }).catch(error => {
        })
    }

    retrieveExplorers() {
        axios.get(this.state.url + 'api/explorers/list/').then(res => {
            this.setState({explorersObject: res.data})
        }).catch(error => {
        })
    }

    handleNewsSelect(id) {
        let newsObject
        for (let n of this.state.newsObjects){
            if (n.id === id) {
                newsObject = n
                break
            }
        }

        this.setState({news: newsObject.news, showUpdateModal: true, id: id, sent: newsObject.sent, genderFilter: newsObject.gender, olderThanFilter: newsObject.older_than, youngerThanFilter: newsObject.younger_than})

    }

    handleSend(id) {
        var csrftoken = this.getCookie('csrftoken');

        let config = {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken
            },
            credentials: "same-origin",
            withCredentials: true
        }
        axios.post(this.state.url + 'api/explorers/news/' + id + "/send_news/", config).then(this.retrieveNews).catch(error => {
        })
    }

    updateNews(id) {
        var csrftoken = this.getCookie('csrftoken');

        let data = {news: this.state.news, 
            gender: this.state.genderFilter == "" ? null : this.state.genderFilter, 
            older_than: this.state.olderThanFilter == "" ? null : this.state.olderThanFilter, 
            younger_than: this.state.youngerThanFilte == "" ? null : this.state.youngerThanFilter }

        let config = {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrftoken
            },
            credentials: "same-origin",
            withCredentials: true
        }

        axios.put(this.state.url + 'api/explorers/news/' + id + "/", data, config).then(res => {
            this.retrieveNews()
        }).catch(error => {
        })
        this.handleCloseModal()
    }


    getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    
    render() {
        if (this.state.isAuthenticated === false) {
            return (
                <Login csrf={this.state.csrf} onLogin={() => {
                    this.setState({isAuthenticated: true})
                    this.retrieveExplorers()
                    this.retrieveNews()
                }} />
            );
        } else if (this.state.isAuthenticated === true) {
            return (
                <div className="container">
                    <h1>Vitality Explorer News</h1>
                    <div className="row button-container">
                        <h2>News</h2>
                        <div className="col-md-1 offset-md-8">
                            <Button variant="primary" onClick={this.handleOpenModal}>
                                New
                            </Button>
                        </div>
                        <div className="col-md-1">

                            <Button variant="secondary" onClick={this.handleLogout}>
                                Logout
                            </Button>
                        </div>

                        <NewsModal show={this.state.showModal} onHide={this.handleCloseModal} onCloseModal={this.handleCloseModal} onSubmitNews={this.handleSubmitNews} onTextAreaChange={this.handleTextAreaChange} onGenderFilterChange={this.handleGenderFilterChange} onOlderThanFilterChange={this.handleOlderThanFilterChange} onYoungerThanFilterChange={this.handleYoungerThanFilterChange} onSend={this.handleSend}></NewsModal>
                        <NewsModal show={this.state.showUpdateModal} onHide={this.handleCloseModal} onCloseModal={this.handleCloseModal} onSubmitNews={this.updateNews} onTextAreaChange={this.handleTextAreaChange} onGenderFilterChange={this.handleGenderFilterChange} onOlderThanFilterChange={this.handleOlderThanFilterChange} onYoungerThanFilterChange={this.handleYoungerThanFilterChange} defaultText={this.state.news} gender={this.state.genderFilter} olderThan={this.state.olderThanFilter} youngerThan={this.state.youngerThanFilter} id={this.state.id} sent={this.state.sent}></NewsModal>
                    </div>
                    <div className="row">
                        <div className="table-wrapper col-md-8 offset-md-2"><NewsTable news={this.state.newsObjects} onClick={this.handleNewsSelect} onSend={this.handleSend}/></div>
                    </div>

                    <h2 className="margin-top margin-bottom">Explorers</h2>

                    <div className="row">
                        <div className="table-wrapper col-md-8 offset-md-2"><ExplorersTable explorers={this.state.explorersObject}/></div>

                    </div>
                </div>
            );
        }

        return null
    }
}

export default Dashboard




