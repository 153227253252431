import { Component } from 'react'
import NumberFieldInput from './NumberFieldInput.jsx'
import PhoneFieldInput from './PhoneFieldInput.jsx'
import TextSelectionInput from './TextSelectionInput.jsx'
import TextFieldInput from './TextFieldInput.jsx'
import QuestionBox from './QuestionBox.jsx'
import CSRFToken from '../csrftoken.js'
import './Signup.css'
import VEN from '../VEN.png';

import axios from "axios"

class Signup extends Component {
    constructor(props) {
        super(props)
        // Bind new functions for next and previous

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.validateData = this.validateData.bind(this)

        this.state = {
            age: "",
            gender: "",
            phone: "",
            name: "",
            valid: false,
            url: window.location.href === "http://localhost:3000/" ? "http://localhost:8000/" : "https://vyscore.com/"
        }

        this.fields = ["age", "gender", "phone", "name"]
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.validateData()) {
            // Logic for notification to complete form
            //console.log(this.state)
            return
        }

        let data = {}
        for (var i = 0; i < this.fields.length; i++) {
            let field = this.fields[i]
            data[field] = this.state[field]
        }


        axios.post(this.state.url + 'api/explorers/signup/', data)
            .then(res => {
                this.setState({ "token": res.data.token })
            }).catch(error => {
                this.setState({ "error": true })
            })
    }

    validateData() {
        for (var i = 0; i < this.fields.length; i++) {
            let field = this.fields[i]
            if (this.state[field] === "") {
                return false
            }
        }
        return true
    }

    render() {
        let gender_values = { "M": "M", "F": "F", "O": "Other" }


        if (this.state.token !== undefined) {
            return (
                <div className="response">
                    <div className="header">
                        <h2>Thank you for signing up for </h2>
                        <h1>Vitality Explorer News<sub>TM</sub></h1>
                    </div>

                    <div className="footer">
                        <div className="new-line"></div>

                        <h3>Please use the following link to get your FREE <i>Dare To Be Vital Book</i> Chapter.</h3>
                        <div className="new-line"></div>

                        <h3>The link is valid for one time use within 24 hours.</h3>
                        <div className="new-line"></div>

                        <a href={this.state.url + "api/v1/survey/file?token=" + this.state.token}>Download</a>
                        Copyright 2021, All Rights Reserved.
                    </div>
                </div>
            )
        }

        return (
            <div className="survey-form">
                <div className="header">
                    <div className="new-line"></div>
                    <h2><a href="https://vitalityexplorers.substack.com">Visit Vitality Explorers on Substack</a></h2>
                    <h3>Learn How To Live Your Most Vital Life</h3>
                    <div className="new-line"></div>
                    <img src={VEN} className="ven-image"/>
                    <div className="new-line"></div>
                    <h2><b>Why should you sign up for <i>Vitality Explorer News?</i></b></h2>
                    <div className="new-line"></div>
                    <h3><b>It's a FREE Text Message Newsletter.</b></h3>
                    <div className="new-line"></div>
                    <h3><b>A FREE <a href="https://vytext.com/ycxbwhn6">Dare To Be Vital Book</a> Chapter <br></br>will be sent to anyone that signs up.</b></h3>
                    <div className="new-line"></div>
                    <h3><b>It's Scientifically Designed to Enhance Your Vitality.</b></h3>
                    <div className="new-line"></div>

                    <div className="alert alert-danger alert-dismissible fade show" hidden={!this.state.error} role="alert">
                        Unable to sign up. Please make sure the phone number used is valid and unique. If you have already signed up, you cannot sign up again.
                        <button type="button" className="button close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>


                </div>
                <form onSubmit={this.handleSubmit}>
                    <CSRFToken />
                    <QuestionBox question="Name:" children={<TextFieldInput name="name" onChange={this.handleChange} />} />

                    <QuestionBox question="Age:" children={<NumberFieldInput name="age" onChange={this.handleChange} />} />
                    <QuestionBox question="Sex:" children={<TextSelectionInput name="gender" onChange={this.handleChange} values={gender_values} />} />
                    <QuestionBox question="Phone Number:" children={<PhoneFieldInput name="phone" onChange={this.handleChange} />} />
                    <div className="submit-div">
                        <button className="submit-btn" type="submit" >Submit</button>
                    </div>
                </form>
                <div className="footer">
                  
                    <div className="new-line"></div>
                    <h3><b>Vitality Explorer News is elite scientific information about how to live your best life. The news is produced by board certified orthopedic surgeon and VitalityExplorer.com founder <a href="https://www.linkedin.com/in/allanmishra/">Dr. Allan Mishra</a></b></h3>
                    <div className="new-line"></div>
                

                    <h3 className='left-align'><b>Vitality Explorer News also helps you answer questions like the ones below:</b></h3>
                    <div className="new-line"></div>
                    <ul>
                        <li><h3 className='left-align'><b>How can I optimize my time?</b></h3></li>
                        <div className="new-line"></div>
                        <li><h3 className='left-align'><b>How can I pinpoint my peak purpose for being on the planet?</b></h3></li>
                        <div className="new-line"></div>
                        <li><h3 className='left-align'><b>How can I turn Sleep into a Superpower?</b></h3></li>
                        <div className="new-line"></div>
                        <li><h3 className='left-align'><b>How can I cultivate closeness with friends and family?</b></h3></li>
                        <div className="new-line"></div>
                        <li><h3 className='left-align'><b>What are the best ways to improve my physical health?</b></h3></li>
                        <div className="new-line"></div>
                        <li><h3 className='left-align'><b>What foods should I eat to enhance my vitality?</b></h3></li>
                    </ul>
                    <div className="new-line"></div>
                    <h3 className='left-align'><b>Then you want to sign up for Vitality Explorer News.</b></h3>
                    <div className="new-line"></div>

                    <h3 className='left-align'><b>All of the information presented within Vitality Explorer News is supported by peer reviewed published scientific data.</b></h3>
                    <div className="new-line"></div>
                    <div className="new-line"></div>
                    <div className="new-line"></div>

                    <h3 className='left-align'><b>By signing up you agree to the disclaimer below.</b></h3>
                    <div className="new-line"></div>

                    <h4><i>Disclaimer: The information on this site is not intended or implied to be a substitute for professional medical advice, diagnosis or treatment. All content, including text, graphics, images and information, contained on or available through this service is for general information purposes only.</i></h4>
                    <div className="new-line"></div>


                    Copyright 2022-2023, All Rights Reserved.
                </div>
            </div>)
    }

}

export default Signup