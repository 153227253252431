import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button'


class NewsTable extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleOlderThanFilterChange = this.handleOlderThanFilterChange.bind(this)
        this.onPasteOlderThanFilter = this.onPasteOlderThanFilter.bind(this)
        this.handleGenderFilterChange = this.handleGenderFilterChange.bind(this)
        this.onPasteGenderFilter = this.onPasteGenderFilter.bind(this)
        this.handleYoungerThanFilterChange = this.handleYoungerThanFilterChange.bind(this)
        this.onPasteYoungerThanFilter = this.onPasteYoungerThanFilter.bind(this)

    }

    handleClick(id) {
        return () => {
            this.props.onClick(id)
        }
    }

    handleOlderThanFilterChange(e) {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        this.props.onOlderThanFilterChange(e)
    }


    onPasteOlderThanFilter(e) {
        e.preventDefault()
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        this.props.onOlderThanFilterChange(e)
    }

    handleGenderFilterChange(e) {
        e.target.value = e.target.value.replace(/[^MFO]/g, '');
        this.props.onGenderFilterChange(e)
    }


    onPasteGenderFilter(e) {
        e.preventDefault()
        e.target.value = e.target.value.replace(/[^MFO]/g, '');
        this.props.onGenderFilterChange(e)
    }

    handleYoungerThanFilterChange(e) {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        this.props.onYoungerThanFilterChange(e)
    }


    onPasteYoungerThanFilter(e) {
        e.preventDefault()
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
        this.props.onYongerThanFilterChange(e)
    }

    render() {

        let sendButton = this.props.send ? <Button variant="primary">Send</Button> : <div></div>
        let saveButton = this.props.sent === true ? <div></div> : <Button variant="primary" onClick={() => {
            return this.props.onSubmitNews(this.props.id)
        }}>
            Save
        </Button>

        return (<Modal show={this.props.show} onHide={this.props.onHide} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title>News</Modal.Title>
            </Modal.Header>

            <div className="container">
                <div className="row">
                    
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                    <h2>Filters</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                    Gender:
                    <input className='form-control' onChange={this.handleGenderFilterChange} onPaste={this.onPasteGenderFilter} defaultValue={this.props.gender} disabled={this.props.sent === true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                    Older than:
                    <input className='form-control' type="tel" min="0" onChange={this.handleOlderThanFilterChange} onPaste={this.onPasteOlderThanFilter} defaultValue={this.props.olderThan } disabled={this.props.sent === true}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                    Younger than:
                    <input className='form-control' type="tel" min="0" onChange={this.handleYoungerThanFilterChange} onPaste={this.onPasteYoungerThanFilter} defaultValue={this.props.youngerThan} disabled={this.props.sent === true}/>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                    <h2>Message</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 offset-md-2 margin-top margin-bottom">
                        <textarea className="form-control news-text-area" placeholder="Enter your news here:" rows="20" onChange={this.props.onTextAreaChange} disabled={this.props.sent === true} defaultValue={this.props.defaultText}>
                        </textarea>
                    </div>
                </div>
            </div>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.props.onCloseModal}>
                    Close
                </Button>
                {saveButton}
            </Modal.Footer>
        </Modal>);
    }

}

export default NewsTable