import { Component } from 'react'
import './TextSelectionInput.css'

class TextSelectionInput extends Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)

    }

    handleChange(e) {
        this.props.onChange(e);
    }

    render() {
        // Get list of values to select
        let values = []
        for (const [key, value] of Object.entries(this.props.values)) {
            values.push(<input type="radio" key={this.props.name + "_radio_" + key} id={this.props.name + "_radio_" + key} name={this.props.name} value={key} required onChange={this.handleChange} />)
            values.push(<label className="no-select" htmlFor={this.props.name + "_radio_" + key} key={this.props.name + "_label_" + key}>{value}</label>)
            values.push(<div className="vl" key={this.props.name + "vl" + key}></div>)
        }

        values.pop()

        return (

            <div className="text-radio-box">
                {values}
            </div>

        )
    }

}

export default TextSelectionInput