import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


class ExplorersTable extends Component {
    constructor(props) {
        super(props)
    }



    render() {
        return (<table className="table">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Age</th>
                </tr>
            </thead>
            <tbody>

                {this.props.explorers.map((object, i) => { return <tr key={object.phone}><td>{object.name}</td><td>{object.phone}</td><td>{object.gender}</td><td>{object.age}</td></tr>} )}
            </tbody>
        </table>);
    }

}

export default ExplorersTable