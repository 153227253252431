import { Component } from 'react'
import './QuestionBox.css'

class QuestionBox extends Component {
    render() {
        return (
            <div className="question-box">
                <label className="question-label">{this.props.question}</label>
                {this.props.children}
            </div>
        )
    }

}

export default QuestionBox